import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardBOTIzbaPamieci = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/minsk-mazowiecki/bot-izba-pamieci/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/minsk-mazowiecki/izba-pamieci-bot'}
      title={'Izba pamięci BOT'}
    >
      Rozkazem z dnia 29 sierpnia 1994 roku rozpoczęto tworzenie 2
      Mińsko-Mazowieckiej Brygady Obrony Terytorialnej im. gen. dyw. Franciszka
      Kleeberga. Miejscem stacjonowania jednostki, która została ostatecznie
      sformowana w lipcu 1995 roku został Mińsk Mazowiecki. W koszarach
      znajdujących się w mieście nad Srebrną przebywała ona przez dziesięć lat.
      2005 rok przyniósł rozformowanie 2MMBOT. Liczne pamiątki związane z jej
      funkcjonowaniem można oglądać w jednym z pomieszczeń Działu 7 Pułku Ułanów
      Lubelskich Muzeum Ziemi Mińskiej w Mińsku Mazowieckim.
    </MonumentCard>
  )
}

export default MonumentCardBOTIzbaPamieci
