import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardMZMOkrzei = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/minsk-mazowiecki/mzmokrzei/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/minsk-mazowiecki/muzeum-ziemi-minskiej-przy-okrzei'}
      title={'Muzeum Ziemi Mińskiej przy ul. Okrzei'}
    >
      Od 2005 roku w willi hrabiny Łubieńskiej jedną ze swoich siedzib posiada
      Muzeum Ziemi Mińskiej w Mińsku Mazowieckim. Placówka ta została powołana
      do życia na mocy Uchwały Rady Miasta Nr XXVI/277/05 z dnia 7 marca 2005
      roku. Piętrowy budynek znajdujący się przy ulicy Stefana Okrzei 16 w
      Mińsku Mazowieckim stanowi miejsce gromadzenia różnorodnych przedmiotów
      związanych z przeszłością ziemi mińskiej oraz organizowania kulturalnych
      przedsięwzięć.
    </MonumentCard>
  )
}

export default MonumentCardMZMOkrzei
