import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardMuzeumWsiStojadla = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/minsk-mazowiecki/muzeum-wsi-stojadla/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/minsk-mazowiecki/muzeum-wsi-stojadla'}
      title={'Muzeum wsi Stojadła'}
    >
      Bardzo duże zasługi w upowszechnianiu wiedzy o historycznych wydarzeniach
      i zwyczajach związanych z ziemią mińską posiada mieszkaniec Stojadeł
      Zbigniew Drewicz. Z zawodu ślusarz przez kilkadziesiąt lat życia parał się
      różnymi profesjami. Od 1991 roku nakręcił kilkadziesiąt dokumentów
      uwieczniających wydarzenia z życia społeczno-kulturalnego Mińska
      Mazowieckiego i Stojadeł. Dla drugiej z wymienionych miejscowości zasłużył
      się też zakładając stowarzyszenie miłośników historii Stojadeł ,,Stojadła
      Wczoraj i Dziś". Z jego inicjatywy, powstała również sala poświęcona
      przeszłości wsi, którą zamieszkuje. Wspomniane aktywności nie wyczerpują
      listy działań Zbigniewa Drewicza. Czynnie działa on w Towarzystwie
      Przyjaciół Mińska Mazowieckiego i zespole ludowym ,,Mazowszaki". Jest też
      jednym z niewielu lokalnych ekspertów od gry na ligawce.
    </MonumentCard>
  )
}

export default MonumentCardMuzeumWsiStojadla
