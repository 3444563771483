import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardCmentarzZydowskiMinskMazowiecki = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/minsk-mazowiecki/cmentarz-zydowski/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  console.log(file)

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/minsk-mazowiecki/cmentarz-zydowski-w-minsku-mazowieckim'}
      title={'Cmentarz Żydowski w Mińsku Mazowieckim'}
    >
      Po społeczności Żydowskiej w Mińsku Mazowieckim pozostało niewiele
      pamiątek. Domy nie zdradzają swoich przedwojennych żydowskich właścicieli.
      Spacery z Historią które prowadzi w Mińsku Ewa Borkowska uchylają rąbka
      tajemnicy. Najbardziej widocznym śladem jest tzw Nowy Cmentarz Żydowski.
      Nekropolia w ciszy i cieniu drzew zabiera nas do czasów gdy miasto i kraj
      nie były religijnym monolitem.
    </MonumentCard>
  )
}

export default MonumentCardCmentarzZydowskiMinskMazowiecki
