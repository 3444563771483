import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCard23BLOT = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/minsk-mazowiecki/23blot/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={
        '/gminy/minsk-mazowiecki/23-baza-lotnictwa-taktycznego-im-pplk-pil-jana-zumbacha'
      }
      title={'23. Baza Lotnictwa Taktycznego im. ppłk. pil. Jana Zumbacha'}
    >
      Rozkazem Dowódcy Wojsk Lotniczych i Obrony Powietrznej z dnia 28 listopada
      2000 roku z dniem 1 stycznia 2001 roku została sformowana 23 Baza
      Lotnicza. Wraz z 1 eskadrą lotnictwa taktycznego powstała ona w miejsce
      rozformowanego 1 pułku lotnictwa myśliwskiego „Warszawa”. Wobec tego
      odziedziczyła ona tradycje zarówno tej jednostki założonej w 1943 roku w
      rosyjskim Grigoriewskoje, jak i innych historycznie związanych z 1 pułkiem
      lotniczym. Informacje, pamiątki i inne ważne świadectwa dotyczące dziejów
      lotniczych formacji stacjonujących na przestrzeni lat nieopodal Mińska
      Mazowieckiego można obejrzeć w sali tradycji 23 Bazy Lotniczej.
    </MonumentCard>
  )
}

export default MonumentCard23BLOT
