import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardTPMM = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/minsk-mazowiecki/zbiorytpmm/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={
        '/gminy/minsk-mazowiecki/zbiory-towarzystwa-przyjaciol-minska-mazowieckiego'
      }
      title={'Towarzystwo Przyjaciół Mińska Mazowieckiego'}
    >
      Od prawie sześćdziesięciu lat ważną rolę we współczesnych dziejach miasta
      nad Srebrną pełni Towarzystwo Przyjaciół Mińska Mazowieckiego. Pozarządowa
      organizacja, której korzeni można doszukiwać się w dwudziestoleciu
      międzywojennym dba o zachowanie pamięci o przeszłości nadsrebrzańskiego
      grodu. Pomocne w realizacji tego celu są rozmowy z mińszczanami, kwerendy
      archiwalne oraz pozyskiwanie różnych świadectw historii. Odpowiednio
      eksponowane przypominają one zainteresowanym osobom o mniej lub bardziej
      interesujących faktach z mińskomazowieckich dziejów.
    </MonumentCard>
  )
}

export default MonumentCardTPMM
