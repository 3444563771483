import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardCmentarzParafialnyMinskMazowiecki = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/minsk-mazowiecki/cmentarz-parafialny/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={
        '/gminy/minsk-mazowiecki/cmentarz-parafialny-w-minsku-mazowieckim'
      }
      title={'Cmentarz Parafialny w Mińsku Mazowieckim'}
    >
      Około ośmiu hektarów wynosi powierzchnia cmentarza parafialnego w Mińsku
      Mazowieckim. Choć początek funkcjonowania nadsrebrzańskiego miejsca
      wiecznego spoczynku trudny jest do ustalenia przypuszcza się, że powstało
      ono pod koniec osiemnastego stulecia. Najstarsze zabytkowe nagrobki, które
      jeszcze dziś można zobaczyć odwiedzając nekropolię datowane są natomiast
      na dziewiętnaste stulecie. Pieczołowicie odnawiane są jednymi z
      najcenniejszych obiektów zabytkowych znajdujących się w grodzie nad
      Srebrną. Co więcej wraz z grobami osób zasłużonych dla rozwoju miasta i
      kwaterami żołnierskimi stanowią one ważne świadectwo dziejów Mińska
      Mazowieckiego.
    </MonumentCard>
  )
}

export default MonumentCardCmentarzParafialnyMinskMazowiecki
