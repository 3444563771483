import React from 'react'
import ArchiveCardWrapper from '../../../components/ArchiveCardWrapper/ArchiveCardWrapper'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import SEO from '../../../components/seo'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Header1 } from '../../../components/Headers/Headers'
import MonumentCard23BLOT from '../../../monuments/minsk-mazowiecki/MonumentCard23BLOT'
import MonumentCardCmentarzParafialnyMinskMazowiecki from '../../../monuments/minsk-mazowiecki/MonumentCard23CmentarzParafialny'
import MonumentCardCmentarzZydowskiMinskMazowiecki from '../../../monuments/minsk-mazowiecki/MonumentCard23CmentarzZydowski'
import MonumentCardMuzeumWsiStojadla from '../../../monuments/minsk-mazowiecki/MonumentCardMuzeumWsiStojadla'
import MonumentCardMZM7PUL from '../../../monuments/minsk-mazowiecki/MonumentCardMZM7PUL'
import MonumentCardMZMOkrzei from '../../../monuments/minsk-mazowiecki/MonumentCardMZMOkrzei'
import MonumentCardTPMM from '../../../monuments/minsk-mazowiecki/MonumentCardTPMM'
import MonumentCardMBHallerowskaNNMP from '../../../monuments/minsk-mazowiecki/MonumentCardMBHallerowskaNNMP'
import MonumentCardBOTIzbaPamieci from '../../../monuments/minsk-mazowiecki/MonumentCardBOTIzbaPamieci'
import MonumentCardRocznik from '../../../components/MonumentCard/MonumentCardRocznik'
import MonumentCardAsset from '../../../components/MonumentCard/MonumentCardAsset'

const MinskMazowieckiArchive = () => {
  return (
    <ArchiveWrapper>
      <SEO title="Materiały dotyczące miasta Mińsk Mazowiecki oraz otaczającej go gminy" />
      <ArchiveCardWrapper>
        <Header1>
          Materiały dotyczące miasta Mińsk Mazowiecki oraz otaczającej go gminy
        </Header1>
        <MonumentCard23BLOT />
        <MonumentCardCmentarzParafialnyMinskMazowiecki />
        <MonumentCardCmentarzZydowskiMinskMazowiecki />
        <MonumentCardMuzeumWsiStojadla />
        <MonumentCardMZM7PUL />
        <MonumentCardMZMOkrzei />
        <MonumentCardTPMM />
        <MonumentCardMBHallerowskaNNMP />
        <MonumentCardBOTIzbaPamieci />
        <MonumentCardRocznik
          title={
            'Kazimierz Sobolewski (1865–1935), ksiądz, działacz polityczny, poseł na Sejm RP'
          }
          authors={
            'Andżelika Kuć, Monika Szczepaniuk, Karolina Gańko, Konrad Czerski, Artur Milewski'
          }
          linkTo={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/rocznik/SiennicaRoczniktom262018WaldemarVALDIPiekarskiStanislawBenedyktDluzewskiijegozacniantenaciszkicgenealogiczny.pdf'
          }
        />
        <MonumentCardRocznik
          title={
            'Piłsudczycy bez Piłsudskiego. Obóz Zjednoczenia Narodowego w powiecie mińskim 1937-1939'
          }
          author={'Damian Sitkiewicz'}
          linkTo={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/rocznik/MinskMazowieckiRoczniktom262018DamianSitkiewiczPilsudczycybezPilsudskiego..pdf'
          }
        />
        <MonumentCardRocznik
          title={'Kazimierz i Antoni Przekora'}
          author={'Przemysław Fedorowicz'}
          linkTo={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/rocznik/MinskMazowieckiRoczniktom262018PrzemyslawFedorowiczKazimierziAntoniPrzekora.pdf'
          }
        />
        <MonumentCardAsset
          title={
            'Noc Śmierci. Zbrodnia komunistyczna w Mińsku Mazowieckim 2-3 marca 1945 r.'
          }
          assetName={'MATERIAŁ'}
          pdfIcon
          linkTo={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/minskmazowiecki/Noc+S%CC%81mierci.pdf'
          }
          buttonText={'Otwórz'}
        />
        <MonumentCardAsset
          title={
            'Tadeusz Doria-Dernałowicz (1916-1956) - ostatni dziecic Repek i Mińska Mazowiecki'
          }
          author={'Damian Sitkiewicz'}
          assetName={'AUDIOBOOK'}
          audioIcon
          audioURL={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Damian+Sitkiewicz+-+Tadeusz+Doria-Derna%C5%82owicz+(1916-1956)+-+ostatni+dziecic+Repek+i+Min%CC%81ska+Mazowiecki.mp3'
          }
        />
        <MonumentCardAsset
          title={
            'Przedsiębiorstwo Komunikacji Samochodowej w Mińsku Mazowieckim'
          }
          author={'Tadeusz Perczyński'}
          assetName={'AUDIOBOOK'}
          audioIcon
          audioURL={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Tadeusz+Perczyn%CC%81ski+-+Przedsie%CC%A8biorstwo+Komunikacji+Samochodowej+w+Min%CC%81sku+Mazowieckim.mp3'
          }
        />
        <MonumentCardAsset
          title={'Historia przystanku PKP Mińsk Mazowiecki - Anielina'}
          author={'Zbigniew Grzesiak'}
          assetName={'AUDIOBOOK'}
          audioIcon
          audioURL={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Zbigniew+Grzesiak+-+Historia+przystanku+PKP+Min%CC%81sk+Mazowiecki+-+Anielina.mp3'
          }
        />
        {/* NOWE */}
        <MonumentCardAsset
          title={'Była Taka Fabryka - Historia Zakładów Rudzki i Spółka'}
          author={'Arkadiusz Łukasiak'}
          assetName={'AUDIOBOOK'}
          audioIcon
          audioURL={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/By%C5%82a+Taka+Fabryka+-+Historia+Zak%C5%82ado%CC%81w+Rudzki+i+Spo%CC%81%C5%82ka%2C+Arkadiusz+%C5%81ukasiak+%3A+tom+17%2C+2009.mp3'
          }
        />
        <MonumentCardAsset
          title={'Mariawityzm w Mińsku Mazowieckim'}
          authors="Krzysztof Mazur, ks. Jan Opala"
          assetName={'AUDIOBOOK'}
          audioIcon
          audioURL={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Mariawityzm+w+Min%CC%81sku+Mazowieckim%2C+Krzysztof+Mazur%2C+ks.+Jan+Opala+%3A+Tom+27%2C+2019+r.mp3'
          }
        />
        <MonumentCardAsset
          title={
            'Przymusowa praca Żydów powiatu mińskiego - placówki i kolumny robocze przed Akcją Reinhardt (1939-1942)'
          }
          author="Ewa Maria Borkowska"
          assetName={'AUDIOBOOK'}
          audioIcon
          audioURL={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Przymusowa+praca+Z%CC%87ydo%CC%81w+powiatu+min%CC%81skiego+-+placo%CC%81wki+i+kolumny+robocze+przed+Akcja%CC%A8+Reinhardt+(1939-1942)+-+Ewa+Maria+Borkowska+%3A+tom+24%2C+2016+r.mp3'
          }
        />
        <MonumentCardAsset
          title={
            'Tajne nauczanie w Mińsku Mazowieckim i powiecie w latach 1939-1944'
          }
          authors="Tadeusz i Władysław Wójcik"
          assetName={'AUDIOBOOK'}
          audioIcon
          audioURL={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Tajne+nauczanie+w+Min%CC%81sku+Mazowieckim+i+powiecie+w+latach+1939-1944+Tadeusz+i+W%C5%82adys%C5%82aw+Wo%CC%81jcik+%3A+Tom+2%2C+1994+r.mp3'
          }
        />
        <MonumentCardAsset
          title={'Wydarzenia 1939-1944'}
          author="Edmund Rajńsz"
          assetName={'AUDIOBOOK'}
          audioIcon
          audioURL={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Wydarzenia+1939-1944+Edmund+Rajn%CC%81sz+%3A+Tom+5%2C+1999+r.mp3'
          }
        />
        <MonumentCardAsset
          title={'Właściciele Mińsk w świetle dokumentów'}
          author="Władysław Rudziński"
          assetName={'AUDIOBOOK'}
          audioIcon
          audioURL={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/W%C5%82as%CC%81ciciele+Min%CC%81sk+w+s%CC%81wietle+dokumento%CC%81w%2C+W%C5%82adys%C5%82aw+Rudzin%CC%81ski%2C+tom+1%2C+1992.mp3'
          }
        />
        <MonumentCardAsset
          title={
            'Z dziejów konspiracyjnego harcerstwa kompania szarych szeregów obwodu armii krajowej mewa mińsk mazowiecki'
          }
          author="Dr hab. Zbigniew Gnat-Wieteska, prof. AON"
          assetName={'AUDIOBOOK'}
          audioIcon
          audioURL={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Z+dziejo%CC%81w+konspiracyjnego+harcerstwa+kompania+szarych+szerego%CC%81w+obwodu+armii+krajowej+mewa+min%CC%81sk+mazowiecki%2C+Dr+hab.+Zbigniew+Gnat-Wieteska%2C+prof.+AON%2C+Tom+17%2C+2009+r.mp3'
          }
        />
      </ArchiveCardWrapper>
    </ArchiveWrapper>
  )
}

export default MinskMazowieckiArchive
