import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardMZM7PUL = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/minsk-mazowiecki/mzm7pul/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={
        '/gminy/minsk-mazowiecki/muzeum-ziemi-minskiej-7-pulk-ulanow-lubelskich'
      }
      title={'Muzeum Ziemi Mińskiej - 7 Pułk Ułanów Lubelskich'}
    >
      Od początku funkcjonowania Muzeum Ziemi Mińskiej w Mińsku Mazowieckim
      posiadało do swojej dyspozycji dwa budynki. Główną siedzibę placówki
      zlokalizowano w znajdującym się przy ulicy Stefana Okrzei 16 pałacyku
      Łubieńskiej. W willi Hubertów, mieszczącej się przy ul. Generała
      Kazimierza Sosnkowskiego 4 można obejrzeć pamiątki związane z 7 Pułkiem
      Ułanów Lubelskich. Jednostka ta stacjonowała w Mińsku Mazowieckim w latach
      1923 – 1939.
    </MonumentCard>
  )
}

export default MonumentCardMZM7PUL
